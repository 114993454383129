import React from "react";
import { RWebShare } from "react-web-share";
import { MdIosShare } from "react-icons/md";
import url from '../../../utils/url';

export default function ButtonShare() {
  return (
    <RWebShare
        data={{
          text: document.title,
          url: window.location.href,
          title: document.title
        }}
        onClick={() => console.log("click share button")}
      >
        <MdIosShare className="nav-icon" />
      </RWebShare>
  )
}