import React, { useState, useEffect } from "react";
import api from '../../services'
import './index.scss';

const { storyStatistic } = api;

export default function PostStatistic(props) {
  const { storyId } = props;
  const [scope, setScope] = useState([]);
    

  useEffect(() => {
    async function handleLoad () {
      await loadScope();
    }
    handleLoad();
  }, []);

  const loadScope = async () => {
    let response = await storyStatistic({ id: storyId });
    if (response.success) {
      setScope(response.list);
    }
  }

    return (
      <div className="statistic_chart">
        {scope.map((s) => (
          <div className="bar_block" key={"bar"+s.scope}>
            <div className="bar_text" style={{bottom: "calc("+s.proc+"% + 15px)"}}><span className={"bar_label"+s.scope+" bar_label"}>{s.proc+"%"}</span></div>
            <div className={"bar"+s.scope+" bar"} style={{height: s.proc+"%"}}></div>
          </div>
        ))}
        <div className="clear"></div>
      </div>
    )
}