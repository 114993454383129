import React from "react";
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import { BiBarChartAlt2 } from 'react-icons/bi';
import { MdOutlineSupervisedUserCircle } from 'react-icons/md';
import UserReactionScope from '../UserReactionScope';
import { APP_ROUTES } from '../../utils/constant';
import url from '../../utils/url';
import './index.scss';

const StoryTitle = ({storyId, title, changeSection, finish, link=false, msg='0', scope=0, fill=true, reactionId=0}) => {

  const clickButton = (e,section) => {
    if (!link) e.preventDefault();
    changeSection(section);
  }

  return (
    <div className={"storyTitle " + (fill ? 'fill' : '')}>
      <Row type="flex" style={{ alignItems: 'center' }} justify="center">
        <Col className="align-center" span={3}>
          { !finish &&
            <Link to={url(`${APP_ROUTES.POST}/${storyId}/statistic`)} onClick={(e) => clickButton(e,'statistic')}><BiBarChartAlt2 className="nav-icon" /></Link>
          }
        </Col>
        <Col className="align-center" span={18} /*onClick={(e) => clickButton(e,'')}*/>
          <div className="storyTitle-title">{title}</div>
        </Col>
        <Col className="align-center" span={3}>
          { !finish &&
          <>
            <Link to={url(`${APP_ROUTES.POST}/${storyId}/reaction`)} onClick={(e) => clickButton(e,'reaction')}><MdOutlineSupervisedUserCircle className="nav-icon" /></Link>
          </>
          }
        </Col>
      </Row>
      { scope!=0 &&
        <UserReactionScope scope={scope} msg_flg={msg} reactionId={reactionId} />
      }
    </div>
  )
}

export default StoryTitle;