import { sort_by_key } from './func';

let request: IDBOpenDBRequest;
let db: IDBDatabase;
let version = 1;
const DB = 'WIWDB';

export interface User {
  id: string;
  name: string;
  email: string;
}

export interface Reaction {
  id: number;
  story: {
    id: number;
    title: string;
  };
  text_msg: string;
  scope: number;
  user: {
    id: number;
    user_name: string;
    first_name: string;
    last_name: string;
    picture: string;
  }
}

export enum Stores {
  Users = 'users',
  Reaction = 'reaction'
}

export const initDB = (): Promise<boolean|IDBDatabase> => {
  return new Promise((resolve) => {
    request = indexedDB.open(DB);

    // if the data object store doesn't exist, create it
    request.onupgradeneeded = () => {
      db = request.result;
      if (!db.objectStoreNames.contains(Stores.Reaction)) {
        console.log('Creating Reaction store');
        db.createObjectStore(Stores.Reaction, { keyPath: 'id' });
      }
      // no need to resolve here
    };

    request.onsuccess = (e) => {
      db = request.result;
      // get current version and store it
      version = db.version;
      resolve(request.result);
    };

    request.onerror = (e) => {
      resolve(false);
    };
  });
};

export const addData = <T>(storeName: string, datas: T[]): Promise<T|string|null> => {
  return new Promise((resolve) => {
    request = indexedDB.open(DB, version);

    request.onsuccess = () => {
      console.log('request.onsuccess - addData', datas);
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      /*const store = tx.objectStore(storeName);
      store.add(data);*/
      datas.forEach(data => {
        tx.objectStore(storeName).put(data);
      })

      resolve(null);
    };

    request.onerror = () => {
      const error = request.error?.message
      if (error) {
        resolve(error);
      } else {
        resolve('Unknown error');
      }
    };
  });
};

export const deleteData = (storeName: string, key: string): Promise<boolean> => {
  return new Promise((resolve) => {
    request = indexedDB.open(DB, version);

    request.onsuccess = () => {
      console.log('request.onsuccess - deleteData', key);
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const res = store.delete(key);
      res.onsuccess = () => {
        resolve(true);
      };
      res.onerror = () => {
        resolve(false);
      }
    };
  });
};

export const clearData = (storeName: string): Promise<boolean> => {
  return new Promise((resolve) => {
    request = indexedDB.open(DB, version);

    request.onsuccess = () => {
      console.log('request.onsuccess - clearDataa');
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const res = store.clear();
      res.onsuccess = () => {
        resolve(true);
      };
      res.onerror = () => {
        resolve(false);
      }
    };
  });
};

export const updateData = <T>(storeName: string, key: string, data: T): Promise<T|string|null> => {
  return new Promise((resolve) => {
    request = indexedDB.open(DB, version);

    request.onsuccess = () => {
      console.log('request.onsuccess - updateData', key);
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const res = store.get(key);
      res.onsuccess = () => {
        const newData = { ...res.result, ...data };
        store.put(newData);
        resolve(newData);
      };
      res.onerror = () => {
        resolve(null);
      }
    };
  });
};

export const readReaction = <T>(storeName: string, key: string, queue: number): Promise<T|string|null> => {
  return new Promise((resolve) => {
    request = indexedDB.open(DB, version);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const res = store.get(key);
      res.onsuccess = () => {
        const newData = { ...res.result, ...{ queue: queue} };
        store.put(newData);
        resolve(newData);
      };
      res.onerror = () => {
        resolve(null);
      }
    };
  });
};

export const getStoreData = <T>(storeName: Stores): Promise<T[]> => {
  return new Promise((resolve) => {
    request = indexedDB.open(DB);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = store.getAll();
      res.onsuccess = () => {
        resolve(res.result);
      };
    };
  });
};

export const getSwipeData = <T>(storeName: Stores, key: string, queue:number/*, direct: string*/): Promise<T|{}> => {
  return new Promise((resolve) => {

    request = indexedDB.open(DB);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = store.getAll();
      res.onsuccess = () => {
        let findQueue = res.result.find((x:any) => x.queue === queue);
        let remains = res.result.filter((x:any) => !x.hasOwnProperty('queue'));
        console.log(findQueue);
        console.log(remains);
        if (findQueue) {
          resolve({reaction: findQueue, remains: remains.length});
        } else {
          remains = sort_by_key(remains, 'rang', 'desc');
          resolve({reaction: remains[0], remains: remains.length-1});
        }
      };
    };
  });
};

export const getNextData = <T>(storeName: Stores): Promise<T[]> => {
  return new Promise((resolve) => {
    request = indexedDB.open(DB);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const cursorRequest = store.openCursor();
      cursorRequest.onsuccess = function(event: any) {
        const cursor = event.target.result;
    
        if (cursor) {
          // Process the current record
          console.log('Cursor value:', cursor.value);
          if (!cursor.value.read) {
            console.log('READ');
            db.close();       
            resolve(cursor.value);
          }
          // Move to the next record
          cursor.continue();
        } else {
          // Cursor has reached the end
          console.log('Cursor iteration completed');
        }
    
        // Close the transaction and database
        tx.oncomplete = function() {
          db.close();
        };
      };
    
      cursorRequest.onerror = function(event: any) {
        console.error('Error opening cursor:', event.target.error);
        // Handle the error appropriately
      };
    };
  });
};