import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Reaction from './pages/reaction';
import Login from './pages/login';
import ReactionAdd from './pages/reaction_add';
import StoryAdd from './pages/story_add';
import Profile from './pages/profile';
import Page404 from './pages/404';
import Page403 from './pages/403';
import Settings from './pages/settings';
import { useTranslation } from "react-i18next";
import config from './utils/config';
import { APP_ROUTES } from './utils/constant';
import { initDB } from './utils/idb.ts';
import {Helmet} from "react-helmet";
//import Main from './pages/main'
//import Statistic from './pages/statistic'
//import Reaction from './pages/reaction'

export default function App() {
  const [locale, setLocale] = useState('');
  const { i18n } = useTranslation();

  useEffect(() => {
    let language = window.location.pathname.split('/')[1];
    if (config.languages.indexOf(language)<0) {
      language = 'en'
    }
    i18n.changeLanguage(language);
    const locale = (language==='en') ? '' : '/'+language;
    setLocale(locale);
  }, [i18n]);

  useEffect(() => {
    async function handleInitDB () {
      await initDB();
    }
    handleInitDB();
  },[])

  return (
      <BrowserRouter>
      <Helmet>
        <title>WIW</title>
        <meta name="description" content="Who is Who" />
      </Helmet>
      <Routes>
        <Route path={`${locale}${APP_ROUTES.MAIN}`} element={<Reaction/> } />
        <Route path={`${locale}${APP_ROUTES.LOGIN}`} element={<Login />} />
        <Route path={`${locale}${APP_ROUTES.POST}/:storyId`} element={<Reaction />} />
        <Route path={`${locale}${APP_ROUTES.POST}/:storyId/:section`} element={<Reaction />} />
        <Route path={`${locale}${APP_ROUTES.REACTION}/:reactionId`} element={<Reaction />} />
        <Route path={`${locale}${APP_ROUTES.REACTION_ADD}/:storyId`} element={<ReactionAdd />} />
        <Route path={`${locale}${APP_ROUTES.STORY_ADD}`} element={<StoryAdd />} />
        <Route path={`${locale}${APP_ROUTES.PROFILE}/:username`} element={<Profile />} />
        <Route path={`${locale}${APP_ROUTES.PROFILE_SETTINGS}`} element={<Settings />} />
        <Route path={`${locale}${APP_ROUTES.PAGE404}`} element={<Page404 />} />
        <Route path={`${locale}${APP_ROUTES.PAGE403}`} element={<Page403 />} />
      </Routes>
    </BrowserRouter>
  );
}
