import React, { useState, useEffect } from "react";
import PostUser from "../PostUser";
import api from '../../services'
//import './index.scss';

const { storyUsers } = api;

export default function StoryUsers(props) {
    const { storyId } = props;
    const [ reactions, setReactions ] = useState([]);

    useEffect(() => {
      async function handleLoad () {
        await loadScope();
      }
      handleLoad();
    }, []);

    const loadScope = async () => {
      let response = await storyUsers({ story_id: storyId });
      if (response.success) {
        setReactions(response.list);
      }
    }

    const reactionItems = reactions.map((r, index) =>
      <PostUser key={index} author={r.user} scope={r.scope} msg_flg={r.text_msg} reactionId={r.id}/>
    );

    return (
      <div  className="content-text">
        {reactionItems}
      </div>
    )
}