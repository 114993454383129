import React  from "react";
import { Link } from 'react-router-dom';
import url from '../../utils/url';
import { APP_ROUTES } from '../../utils/constant';
import { MdMoreHoriz } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/**
 * 
 * @param { number } scope - Оценка реакции
 * @param { string } msg_flg - Есть ли тектовый комментарий (1 - есть | 0 -нет)
 * @param { number } reactionId - Id реакции
 * @param { string } size - Размер прямоугольника
 * @returns 
 */

export default function UserReactionScope({scope=0, msg_flg='0', reactionId=0, size='small'}) {
    const { t } = useTranslation();
    return (
        <Link className={"story_user_scope scope"+scope+" size_"+size} to={url(`${APP_ROUTES.REACTION}/${reactionId}`)}>
                { msg_flg=='1' &&
                    <MdMoreHoriz />
                }
                { size=='big' &&
                    t('reaction.not_comment')
                }
        </Link>
    )
};