import React, { forwardRef } from "react";
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { APP_ROUTES } from '../../utils/constant';
import url from '../../utils/url';
import UserImage from '../UserImage';
import UserReactionScope from '../UserReactionScope';
import { createName } from '../../utils/func';
import './index.scss';


//export default function PostUser({author, scope}) {
const PostUser = forwardRef((props, ref) => {
  const { author, scope, msg_flg, reactionId } = props;
    return (
      <div ref={ref} className="userVote">
        <Row>
          <Col className="align-left" span={4}>
            <Link to={url(`${APP_ROUTES.PROFILE}/${author.user_name}`)}><UserImage picture={author.picture}/></Link>
          </Col>
          <Col className="align-left" span={17}>
            <div className="post_user_data">
              <Link to={url(`${APP_ROUTES.PROFILE}/${author.user_name}`)}>
                <span className="post_user_name">{createName(author)}</span><br/>
                <span className="post_user_acc">@{author.user_name}</span>
              </Link>
            </div>
          </Col>
          <Col className="align-right" span={3}>
            <UserReactionScope scope={scope} msg_flg={msg_flg} reactionId={reactionId}/>
          </Col>
        </Row>
      </div>
    )
});

export default PostUser;